import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/batubara.jpg";
import projImg2 from "../assets/img/batubara2.jpg";
import projImg3 from "../assets/img/batubara3.jpg";
import projImg4 from "../assets/img/nickel.png";
import projImg5 from "../assets/img/nickel2.jpg";
import projImg6 from "../assets/img/nickel3.jpg";
import fuel from "../assets/img/fuel.jpg";
import marine from "../assets/img/marine.jpg";
import lpg from "../assets/img/lpg.jpg";
import lubricant from "../assets/img/lubricant.jpg";
import petrochemical from "../assets/img/petrochemical.jpg";
import aviation from "../assets/img/aviation.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Batu Bara Grade A",
      description: "Produk Batubara dengan kualitas Grade A",
      imgUrl: projImg1,
    },
    {
      title: "Batu Bara Grade B",
      description: "Produk Batubara dengan kualitas Grade B",
      imgUrl: projImg2,
    },
    {
      title: "Batu Bara Grade C",
      description: "Produk Batubara dengan kualitas Grade C",
      imgUrl: projImg3,
    },
  ];

  const projects2 = [
    {
      title: "Fuel",
      description: "Memberikan pilihan produk BBM yang efisien untuk kendaraan bermotor",
      imgUrl: fuel,
    },
    {
      title: "Lubricant",
      description: "Produk-produk pelumas yang berkualitas untuk kendaraan bermotor",
      imgUrl: lubricant,
    },
    {
      title: "Liquified Petroleum Gas (LPG)",
      description: "Produk LPG yang menggunakan teknologi DSVS (Double Spindle Valve System) untuk mencegah terjadinya kebocoran gas.",
      imgUrl: lpg,
    },
    {
      title: "Aviation Fuel",
      description: "Produk BBM untuk sektor penerbangan seperti Avgas, Avtur (Jet A-1), LSFO (Low Sulphur Fuel Oil)",
      imgUrl: aviation,
    },
    {
      title: "Industry & Marine Fuel",
      description: "Produk BBM untuk sektor maritim seperti MFO 180 dan MFO 380 ",
      imgUrl: marine,
    }
  ];

  const projects3 = [
    {
      title: "Petrochemical",
      description: "Produk untuk industri seperti Agrochemical, Aromatic, Bitumen, Cokes, Olefin, Processing Oil, Solvent dan Speciality Chemical",
      imgUrl: petrochemical,
    }
  ];

  return (
    <section className="project" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Our Services</h2>
                <p> Prinsip Bisnis kami didasarkan pada nilai-nilai inti kami dan menunjukkan bagaimana kami mendorong kepercayaan, keterbukaan, kerja tim, profesionalisme, dan kebanggaan dalam apa yang kami lakukan. 
                    Sebagai bagian dari prinsip ini, kami berkomitmen untuk berkontribusi terhadap pengembangan berkelanjutan, menyeimbangkan kepentingan jangka pendek dan jangka panjang, 
                    serta mengintegrasikan pertimbangan ekonomi, lingkungan, dan sosial ke dalam pengambilan keputusan kami. 
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Batu Bara</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Minyak dan Gas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Petrochemical</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projects2.map((project2, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project2}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projects3.map((project3, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project3}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
