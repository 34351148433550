import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Briefly About Us</h2>
                        <p>PT. Elang Hadiningrat adalah perusahaan perdagangan komoditas sumber daya alam yang berdiri sejak tahun 2012 dengan Akta Pendirian No. 229 yang berdomisili di Jakarta.
                           Sebagai perusahaan yang terus berkembang, kami menjalin kemitraan dengan beberapa perusahaan tambang batu bara dan perusahaan sumber daya alam lainnya yang berkualitas
                           berasal dari beberapa penjuru di Indonesia.</p>

                        <h2 className="mb-3">Our Core Value</h2>   
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="search-dollar" size="5x" beatFade/>
                                <h5>Transparency</h5>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="cogs" size="5x" beatFade/>
                                <h5>Efficient</h5>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="hands-helping" size="5x" beatFade/>
                                <h5>Accountability</h5>
                            </div>
                            <div className="item">
                                <FontAwesomeIcon className="py-3" icon="user-shield" size="5x" beatFade/>
                                <h5>Modesty</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
