import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearchDollar, faCogs, faHandsHelping, faUserShield} from "@fortawesome/free-solid-svg-icons";

library.add( faSearchDollar, faCogs, faHandsHelping, faUserShield);

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
