import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import videoBg from '../assets/img/videobg.mp4';
import {Link} from 'react-router-dom';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Your trusted", "Trading Company", "Provider...." ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <video src={videoBg} autoPlay loop muted />
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">PT. Elang Hadiningrat</span>
                <h1> <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Your trusted", "Trading Company", "Provider...." ]'><span className="wrap">{text}</span></span></h1>
                  <p>Sebagai sebuah perusahaan yang beroperasi di dunia yang sangat menantang ini , kami menetapkan standar kinerja dan perilaku etis yang tinggi. Kami dinilai dari cara kami bertindak serta  reputasi kami yang ditegakkan melalui cara kami mengamalkan nilai-nilai inti kami,
                     yaitu kejujuran, integritas, dan rasa hormat kepada sesama manusia.</p>
                   <a href="https://wa.me/6281908012103" target="_blank" rel="noopener noreferrer"><button>Chat Us! <ArrowRightCircle size={25} /></button></a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            
          </Col>
        </Row>
      </Container>
    </section>
  )
}
